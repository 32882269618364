const AdminHome = () => {
  return (
    <div>
      Welcome to EpicDxfDesigns Admin App! Click on any desired link on the left
      sidebar.
    </div>
  );
};

export default AdminHome;
