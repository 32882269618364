import React, { useContext, useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import { useQuery } from '@tanstack/react-query';

import LabelAndInputText from 'components/common/LabelAndInputText';
import FileUpload from 'components/common/FileUpload';
import LabelAndDropdown from 'components/common/LabelAndDropdown';
import Button from 'components/common/Button';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import s3Upload from 'helpers/s3Upload';
import { AuthContext } from 'context/AuthProvider';
import Toast from 'helpers/Toast';
import ReactQueryParameters from 'constants/reactQueryParameters';

const CreateNewResource = () => {
  const previewImageRef = useRef();
  const paidResourcesRef = useRef();

  const liveImgPreviewRef = useRef();

  const [imageData, setImageData] = useState({
    imageName: '',
    previewImageFileName: '',
    imageCategoryIds: [],
    previewImage: null,
    paidResources: [],
    imagePrice: '',
  });
  const paidResourcesDataRef = useRef([]);

  const [validationErrors, setValidationErrors] = useState({
    imageName: '',
    imageCatgories: '',
    previewImage: '',
    paidResources: '',
    imagePrice: '',
  });

  const { auth } = useContext(AuthContext);

  const {
    isPending: imageCategoriesLoading,
    error: imageCategoriesError,
    data: imageCategoriesRes,
  } = useQuery({
    queryKey: ['imageCategory/all'],
    queryFn: () => ajaxRequest('api/imageCategory/all', ajaxRequestMethods.GET),
    staleTime: ReactQueryParameters.queryStaleTime,
    gcTime: ReactQueryParameters.queryGcTime,
  });

  const imageCategories = imageCategoriesRes?.data.map((item) => ({
    value: item._id,
    label: item.imageCategoryName,
  }));

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const validateInput = () => {
    const validationObj = {};

    if (imageData.imageCategoryIds.length === 0)
      validationObj.imageCatgories =
        'Please provide atleast one image category.';
    if (!imageData.imageName)
      validationObj.imageName = 'Please provide image name';
    if (!imageData.previewImage)
      validationObj.previewImage = 'Please provide preview image.';
    if (imageData.paidResources.length === 0)
      validationObj.paidResources = 'Please provide paid resources.';
    if (isNaN(imageData.imagePrice) || imageData.imagePrice < 1)
      validationObj.imagePrice = 'Please provide price>0.';

    return validationObj;
  };

  const resetData = () => {
    setImageData({
      imageName: '',
      imageCategoryIds: [],
      paidResources: [],
      previewImage: null,
      imagePrice: '',
    });
    paidResourcesDataRef.current = [];
    paidResourcesRef.current.value = '';
    previewImageRef.current.value = '';
    liveImgPreviewRef.current.src = '';
    liveImgPreviewRef.current.style.height = '0px';
    liveImgPreviewRef.current.style.width = '0px';
  };

  const onSubmit = async () => {
    try {
      const validationObj = validateInput();
      if (Object.keys(validationObj).length) {
        setValidationErrors(validationObj);
        return;
      }
      setDisableSubmitButton(true);

      //upload preview image to s3 and get the url.
      const {
        data: { url: previewImageUploadUrl, s3Key: imageS3Key },
      } = await ajaxRequest(
        'api/s3Url/upload/previewImage',
        ajaxRequestMethods.GET,
        undefined,
        auth.accessToken,
      );

      await s3Upload(previewImageUploadUrl, imageData.previewImage);

      //upload paid resources to s3 and store their info in paidresources collection in db
      const paidFiles = imageData.paidResources;
      for (let i = 0; i < paidFiles.length; i++) {
        //upload preview image to s3 and get the url.
        const {
          data: { url: paidResourceUploadUrl, s3Key },
        } = await ajaxRequest(
          'api/s3Url/upload/paidResource',
          ajaxRequestMethods.GET,
          undefined,
          auth.accessToken,
        );

        await s3Upload(paidResourceUploadUrl, paidFiles[i]);

        paidResourcesDataRef.current.push({
          s3Key,
          fileName: paidFiles[i].name,
        });
      }

      await ajaxRequest(
        'api/image',
        ajaxRequestMethods.POST,
        {
          imageName: imageData.imageName,
          previewImageFileName: imageData.previewImageFileName,
          imageCategoryIds: imageData.imageCategoryIds,
          imagePrice: imageData.imagePrice,
          imageS3Key,
          paidResources: paidResourcesDataRef.current,
        },
        auth.accessToken,
      );

      Toast.setToastMessage('Image Created');
      resetData();
    } catch (error) {
      Toast.setToastMessage(
        error.message || 'An error occurred',
        Toast.ToastType.ERROR,
      );
    } finally {
      setDisableSubmitButton(false);
    }
  };

  return (
    <div className="flex h-full w-[900px] items-center">
      {imageCategoriesLoading ? (
        <ReactLoading
          type="spin"
          color="blue"
          className="mx-auto mt-20 w-full"
        />
      ) : imageCategoriesError ? (
        <span style={{ color: 'red' }}>
          There was an error. Please refresh the page.
        </span>
      ) : (
        <div className="flex w-2/4 flex-col justify-center gap-y-6">
          <LabelAndInputText
            value={imageData.imageName}
            onValueChange={(imageName) =>
              setImageData((prev) => ({ ...prev, imageName }))
            }
            error={validationErrors.imageName}
            clearError={() =>
              setValidationErrors((prev) => ({ ...prev, imageName: '' }))
            }
            label="Image Name"
            placeholder="Assign a name to be shown to the user while displaying image preview."
          />

          <FileUpload
            label="Preview Image"
            acceptedFileTypes="image/*"
            onChange={(previewImage) => {
              const previewImageFileName = previewImage.name;
              const previewImageFileType = previewImage.type;

              if (!previewImageFileName || !previewImageFileType) {
                setValidationErrors((prev) => ({
                  ...prev,
                  previewImage:
                    'Preview image file must have a name and content type.',
                }));
                previewImageRef.current.value = '';
                return;
              }
              liveImgPreviewRef.current.src = URL.createObjectURL(previewImage);
              setImageData((prev) => ({
                ...prev,
                previewImage,
                previewImageFileName,
              }));
              liveImgPreviewRef.current.style.height = '60px';
              liveImgPreviewRef.current.style.width = '60px';
            }}
            error={validationErrors.previewImage}
            clearError={() =>
              setValidationErrors((prev) => ({ ...prev, previewImage: '' }))
            }
            ref={previewImageRef}
          />
          <img ref={liveImgPreviewRef} width={0} height={0} alt="preview" />

          <FileUpload
            label="Paid Resources"
            onChange={(paidResources) =>
              setImageData((prev) => ({ ...prev, paidResources }))
            }
            error={validationErrors.paidResources}
            clearError={() =>
              setValidationErrors((prev) => ({ ...prev, paidResources: '' }))
            }
            multiple
            ref={paidResourcesRef}
          />

          <LabelAndDropdown
            label="Image Category"
            options={imageCategories}
            currentSelection={imageData.imageCategoryIds}
            onSelectionChange={(imageCategoryIds) => {
              setImageData((prev) => ({ ...prev, imageCategoryIds }));
            }}
            error={validationErrors.imageCategoryIds}
            clearError={() =>
              setValidationErrors((prev) => ({ ...prev, imageCategoryIds: '' }))
            }
            multipleSelection
          />

          <LabelAndInputText
            label="Image Price"
            type="number"
            value={imageData.imagePrice}
            onValueChange={(imagePrice) => {
              setImageData((prev) => ({ ...prev, imagePrice }));
            }}
            min={0}
            error={validationErrors.imagePrice}
            clearError={() =>
              setValidationErrors((prev) => ({ ...prev, imagePrice: '' }))
            }
          />

          <Button
            disabled={disableSubmitButton}
            onClick={onSubmit}
            className="w-32"
          >
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};

export default CreateNewResource;
