import { useContext, useState } from 'react';
import { object, ref, string } from 'yup';
import { useMutation } from '@tanstack/react-query';

import LabelAndInputText from 'components/common/LabelAndInputText';
import Button from 'components/common/Button';
import Regex from 'constants/regex';
import Yup from 'helpers/yup';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import Toast from 'helpers/Toast';
import { AuthContext } from 'context/AuthProvider';

const initialPasswordData = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};
const changePasswordSchema = object({
  oldPassword: string().required('Please provide old password.'),
  newPassword: string()
    .required()
    .matches(
      Regex.passwordRegex,
      'New Password must contain atleast one alphabet, one digit, one special character and atleast six characters.',
    ),
  confirmNewPassword: string()
    .required('Please confirm new password.')
    .oneOf([ref('newPassword')], 'Passwords do not match.'),
});

const ChangePassword = () => {
  const [passwordData, setPasswordData] = useState(initialPasswordData);

  const [validationErrors, setValidationErrors] = useState(initialPasswordData);
  const { auth } = useContext(AuthContext);
  const {
    mutate: makeChangePasswordApiCall,
    isPending: isChangePasswordApiCallInProgress,
  } = useMutation({
    mutationFn: () =>
      ajaxRequest(
        'api/admin/change-password',
        ajaxRequestMethods.POST,
        {
          oldPassword: passwordData.oldPassword,
          newPassword: passwordData.newPassword,
        },
        auth?.accessToken,
      ),
    onSuccess: () => {
      Toast.setToastMessage(
        'Password updated successfully.',
        Toast.ToastType.SUCCESS,
      );
      setPasswordData(initialPasswordData);
    },
    onError: (error) => {
      Toast.setToastMessage(error.message, Toast.ToastType.ERROR);
    },
  });
  const onSubmitChangePassword = () => {
    changePasswordSchema
      .validate(passwordData, { abortEarly: false })
      .then(() => {
        makeChangePasswordApiCall();
      })
      .catch((errors) => {
        setValidationErrors(Yup.extractYupValidationErrors(errors));
      });
  };
  return (
    <div className="flex w-72 flex-col gap-y-4">
      <LabelAndInputText
        value={passwordData.oldPassword}
        onValueChange={(oldPassword) => {
          setPasswordData((prev) => ({ ...prev, oldPassword }));
        }}
        error={validationErrors.oldPassword}
        clearError={() => {
          setValidationErrors((prev) => ({ ...prev, oldPassword: '' }));
        }}
        label="Old Password"
        placeholder="Enter your old password."
        type="password"
      />

      <LabelAndInputText
        value={passwordData.newPassword}
        onValueChange={(newPassword) => {
          setPasswordData((prev) => ({ ...prev, newPassword }));
        }}
        error={validationErrors.newPassword}
        clearError={() => {
          setValidationErrors((prev) => ({ ...prev, newPassword: '' }));
        }}
        label="New Password"
        placeholder="Enter your new password."
        type="password"
      />

      <LabelAndInputText
        value={passwordData.confirmNewPassword}
        onValueChange={(confirmNewPassword) => {
          setPasswordData((prev) => ({ ...prev, confirmNewPassword }));
        }}
        error={validationErrors.confirmNewPassword}
        clearError={() => {
          setValidationErrors((prev) => ({ ...prev, confirmNewPassword: '' }));
        }}
        label="Confirm New Password"
        placeholder="Confirm your new password."
        type="password"
      />

      <Button
        onClick={onSubmitChangePassword}
        className="w-32"
        disabled={isChangePasswordApiCallInProgress}
      >
        Submit
      </Button>
    </div>
  );
};

export default ChangePassword;
