import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import TabButtons from 'components/common/TabButtons';
import routes from 'constants/routes';

const AccountSettingsTabButtons = () => {
  const location = useLocation();

  const accountSettingsTabsData = useMemo(
    () => [
      {
        label: 'Password Settings',
        navigateTo: routes.accountSettings.routes.password,
        isSelected: location.pathname === routes.accountSettings.password,
      },
      {
        label: 'Email Settings',
        navigateTo: routes.accountSettings.routes.email,
        isSelected: location.pathname === routes.accountSettings.email,
      },
    ],
    [location.pathname],
  );

  return <TabButtons tabsData={accountSettingsTabsData} />;
};

export default AccountSettingsTabButtons;
