import ReactGA from 'react-ga4';

const GaEventCategories = {
  IMAGE_BROWSING: {
    category: 'image_browsing',
    events: {
      SEARCH_TEXT_CHANGE: 'search_text_change',
      PAGE_CHANGE: 'page_change',
      IMAGE_CATEGORY_CHANGE: 'image_category_change',
    },
  },

  IMAGE_INTERACTION: {
    category: 'image_interaction',
    events: {
      PREVIEW_IMAGE_CLICKED: 'preview_image_clicked',
      PREVIEW_IMAGE_ZOOM_IN: 'preview_image_zoom_in',
      PREVIEW_IMAGE_ZOOM_OUT: 'preview_image_zoom_out',
    },
  },
};

const sendGaEvent = (category, action, label) => {
  if (process.env.REACT_APP_GA_MEASUREMENT_ID)
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
};

const ga = { GaEventCategories, sendGaEvent };

export default ga;
