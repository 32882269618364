import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import ReactLoading from 'react-loading';

import { AuthContext } from 'context/AuthProvider';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import { ReactComponent as CloseIcon } from 'svgs/close.svg';
import ReactQueryParameters from 'constants/reactQueryParameters';

const OrderDetails = ({ paymentId, onClose }) => {
  const { auth } = useContext(AuthContext);
  const {
    isFetching: orderDetailsLoading,
    isError: orderDetailsError,
    data: orderDetails,
  } = useQuery({
    queryKey: ['orderDetails', { paymentId }],
    queryFn: () =>
      ajaxRequest(
        `api/order/details?paymentId=${paymentId}`,
        ajaxRequestMethods.GET,
        undefined,
        auth.accessToken,
      ),
    staleTime: ReactQueryParameters.queryStaleTime,
    gcTime: ReactQueryParameters.queryGcTime,
  });
  const orderDate =
    orderDetails?.data &&
    new Date(orderDetails.data.createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
  return (
    <div className="fixed inset-0 z-50 flex h-screen w-screen items-center justify-center bg-black/50">
      <div className="flex max-h-[70%] min-w-[50%] max-w-[60%] flex-col gap-y-2 rounded-lg bg-white pb-2">
        <div className="flex items-center justify-between border-0 border-b border-solid border-gray-200 p-2">
          <label className="text-lg font-semibold">Order Details</label>
          <CloseIcon
            className="h-10 w-10 cursor-pointer text-blue-500"
            onClick={onClose}
          />
        </div>
        {orderDetailsLoading ? (
          <ReactLoading type="spin" color="blue" className="mx-auto" />
        ) : orderDetailsError ? (
          <div className="mx-auto text-red-500">
            There was an error. Please refresh the page
          </div>
        ) : (
          orderDetails && (
            <div className="overflow-auto px-2">
              <div>
                <div>
                  <label className="font-semibold">Payment ID: </label>
                  {orderDetails.data.paymentId}
                </div>
                <div>
                  <label className="font-semibold">Payer Email: </label>
                  {orderDetails.data.payerEmail}
                </div>
                <div>
                  <label className="font-semibold">Subtotal: </label>
                  {orderDetails.data.price}
                </div>
                <div>
                  <label className="font-semibold">Discount: </label>
                  {orderDetails.data.discount}
                </div>
                <div>
                  <label className="font-semibold">Order Date: </label>
                  {orderDate}
                </div>
              </div>
              <div className="mt-4 flex flex-wrap gap-6">
                {orderDetails.data.images.map((image) => (
                  <div className="flex flex-col items-center justify-center">
                    <img
                      src={image.imageDetails.imageUrl}
                      alt={image.imageDetails.imageName}
                      className="h-32 w-32"
                    />
                    <label>{image.imageDetails.imageName}</label>
                    <div>
                      <label className="font-semibold">Current Price:</label>
                      <span>{image.imageDetails.imagePrice}</span>
                    </div>
                    <div>
                      <label className="font-semibold">Buying Price:</label>
                      <span>{image.price}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
