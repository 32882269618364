import { useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AgGridReact } from 'ag-grid-react';
// Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-grid.css';
//optional css for data grid
import 'ag-grid-community/styles/ag-theme-quartz.css';

import { useDebounce } from 'customHooks';
import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import { AuthContext } from 'context/AuthProvider';
import Pagination from 'components/common/Pagination';
import SearchText from 'components/common/SearchText';
import { ReactComponent as DownloadIcon } from 'svgs/download.svg';
import { ReactComponent as DetailsIcon } from 'svgs/details.svg';
import GetDownloadUrl from 'components/AdminComponents/OrderHistory/GetDownloadUrl';
import OrderDetails from 'components/AdminComponents/OrderHistory/OrderDetails';
import ReactQueryParameters from 'constants/reactQueryParameters';

const PopupTypes = {
  NONE: 0,
  SET_DOWNLOAD_URL_EXPIRY_TIME: 1,
  ORDER_DETAILS: 2,
};

const OrderHistory = () => {
  const { auth } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState('');
  const q = useDebounce(searchTerm);
  const [pageNumber, setPageNumber] = useState(0);

  const [selectedPaymentId, setSelectedPaymentId] = useState();
  const [popupType, setPopupType] = useState(PopupTypes.NONE);

  useEffect(() => {
    setPageNumber(0);
  }, [q]);

  const {
    // isFetching: imageCategoriesLoading,
    // error: imageCategoriesError,
    data: orderHistoryRes,
  } = useQuery({
    queryKey: ['orderHistory', { pageNumber, q }],
    queryFn: () =>
      ajaxRequest(
        `api/order/list/search?pageNumber=${pageNumber}${q ? `&q=${q}` : ''}`,
        ajaxRequestMethods.GET,
        undefined,
        auth.accessToken,
      ),
    staleTime: ReactQueryParameters.queryStaleTime,
    gcTime: ReactQueryParameters.queryGcTime,
  });

  const colDefs = useRef([
    { field: 'paymentId', headerName: 'Paypal Payment Id' },
    { field: 'payerEmail' },
    { field: 'payerName' },
    { field: 'price', width: 100, headerName: 'Subtotal' },
    { field: 'discount', width: 100 },
    {
      headerName: 'Total',
      cellRenderer: (params) => params.data.price - params.data.discount,
      width: 100,
    },
    { field: 'createdAt', headerName: 'Order Date' },
    {
      cellRenderer: (params) => (
        <DownloadIcon
          className="h-5 w-5 cursor-pointer"
          title="Get download link of resources ordered in this order."
          onClick={() => {
            setSelectedPaymentId(params.data.paymentId);
            setPopupType(PopupTypes.SET_DOWNLOAD_URL_EXPIRY_TIME);
          }}
        />
      ),
      width: 1,
      resizable: false,
      sortable: false,
    },
    {
      cellRenderer: (params) => (
        <DetailsIcon
          className="h-5 w-5 cursor-pointer"
          onClick={() => {
            setSelectedPaymentId(params.data.paymentId);
            setPopupType(PopupTypes.ORDER_DETAILS);
          }}
          title="View Order Details"
        />
      ),
      resizable: false,
      sortable: false,
      width: 10,
    },
  ]);

  return (
    <div className="flex w-full flex-col gap-y-4 pr-4">
      <SearchText containerClasses="w-72" onTextChange={setSearchTerm} />

      <div className="ag-theme-quartz" style={{ height: 500, width: '100%' }}>
        <AgGridReact
          rowData={orderHistoryRes?.data.orders}
          columnDefs={colDefs.current}
          suppressMovableColumns={true}
        />
      </div>

      <Pagination
        currentPageNo={pageNumber}
        onPageChange={setPageNumber}
        pageCount={orderHistoryRes?.data.totalPages}
      />

      {popupType === PopupTypes.SET_DOWNLOAD_URL_EXPIRY_TIME ? (
        <GetDownloadUrl
          paymentId={selectedPaymentId}
          onClose={() => {
            setPopupType(PopupTypes.NONE);
            setSelectedPaymentId(undefined);
          }}
        />
      ) : (
        popupType === PopupTypes.ORDER_DETAILS && (
          <OrderDetails
            paymentId={selectedPaymentId}
            onClose={() => setPopupType(PopupTypes.NONE)}
          />
        )
      )}
    </div>
  );
};

export default OrderHistory;
