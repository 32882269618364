import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const TabButtons = ({ tabsData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <div className="flex h-fit w-fit border-0 border-b border-solid border-gray-200">
        {tabsData.map((tab) => {
          const isSelected = location.pathname === tab.navigateTo;
          return (
            <button
              key={tab.label}
              className={`text-md cursor-pointer rounded-t-lg border-none px-4 py-2 outline-none ${isSelected ? 'bg-[#006FF4] font-semibold text-white' : 'bg-white font-normal text-gray-500'}`}
              onClick={() => navigate(tab.navigateTo)}
            >
              {tab.label}
            </button>
          );
        })}
      </div>

      <Outlet />
    </>
  );
};

export default TabButtons;
