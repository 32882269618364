import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import ReactQueryParameters from 'constants/reactQueryParameters';

const buttonClasses =
  'text-lg outline-none rounded-lg px-2 py-1 hover:cursor-pointer active:scale-90';
const inactiveButtonColor = 'bg-blue-500 text-white border-none';
const activeButtonColor = 'bg-white text-gray-500 border border-gray-500';

const ImageCategories = ({ currentImageCategory, setCurrentImageCategory }) => {
  const {
    // isFetching: imageCategoriesLoading,
    // error: imageCategoriesError,
    data: imageCategoriesRes,
  } = useQuery({
    queryKey: ['imageCategory/all'],
    queryFn: () => ajaxRequest('api/imageCategory/all', ajaxRequestMethods.GET),
    staleTime: ReactQueryParameters.queryStaleTime,
    gcTime: ReactQueryParameters.queryGcTime,
  });
  const imageCategories = imageCategoriesRes?.data;

  useEffect(() => {
    if (imageCategories?.length > 0) {
      setCurrentImageCategory(imageCategories[0]._id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageCategories]);

  return (
    <div className="flex flex-wrap gap-2">
      <button
        className={`${buttonClasses} ${
          currentImageCategory === undefined
            ? activeButtonColor
            : inactiveButtonColor
        }`}
        onClick={() => setCurrentImageCategory(undefined)}
      >
        ALL
      </button>
      {imageCategories?.map(({ _id, imageCategoryName }) => (
        <button
          key={_id}
          className={`${buttonClasses} ${
            currentImageCategory === _id
              ? activeButtonColor
              : inactiveButtonColor
          }`}
          onClick={() => setCurrentImageCategory(_id)}
        >
          <span>{imageCategoryName}</span>
        </button>
      ))}
    </div>
  );
};

export default ImageCategories;
