const routes = {
  marketplace: '/:currentImageCategorySlug/page/:pageNumber',
  download: '/download/:downloadToken',
  executePaypalPayment: '/paypal/execute',
  verifyEmail: '/verify-email/:token',
  unsubcribe: '/unsubscribe',

  adminAppHome: '/adminapp',
  login: '/adminapp/login',
  addNewImage: '/adminapp/images/add',
  images: '/adminapp/images',
  imageCategories: '/adminapp/image-categories',
  addNewImageCategory: '/adminapp/image-categories/add',
  orderHistory: '/adminapp/order-history',
  discount: '/adminapp/discount',
  accountSettings: {
    baseRoute: '/adminapp/account-settings',
    routes: {
      password: '/adminapp/account-settings/password',
      email: '/adminapp/account-settings/email',
    },
  },
};

export default routes;
