import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import ajaxRequest, { ajaxRequestMethods } from 'helpers/ajaxRequest';
import routerUtils from 'helpers/routerUtils';
import slugUtils from 'helpers/slugUtils';
import { ReactComponent as RightArrow } from 'components/Marketplace/svgs/rightArrow.svg';
import { ReactComponent as BurgerIcon } from 'svgs/burger.svg';
import { ReactComponent as CrossIcon } from 'svgs/cross.svg';
import ga from 'helpers/ga';
import ReactQueryParameters from 'constants/reactQueryParameters';

const ImageCategoryLink = ({
  imageCategoryDetails,
  currentImageCategorySlug,
  onImageCategorySelection,
  img,
  textClasses,
}) => {
  const onImageCategoryClick = () => {
    onImageCategorySelection(imageCategoryDetails.imageCategoryName);

    if (imageCategoryDetails.slug !== currentImageCategorySlug)
      ga.sendGaEvent(
        ga.GaEventCategories.IMAGE_BROWSING.category,
        ga.GaEventCategories.IMAGE_BROWSING.events.IMAGE_CATEGORY_CHANGE,
        imageCategoryDetails.imageCategoryName,
      );
  };
  return (
    <Link
      key={imageCategoryDetails.slug}
      onClick={onImageCategoryClick}
      className={`flex cursor-pointer gap-x-2 border-0 border-t border-solid border-gray-600 px-4 py-2 text-black no-underline ${imageCategoryDetails.slug === currentImageCategorySlug ? 'bg-gray-400' : 'hover:bg-gray-50'}`}
      to={routerUtils.getMarketplaceNavigateUrl(imageCategoryDetails.slug, 1)}
    >
      <span className={textClasses}>
        {imageCategoryDetails.imageCategoryName}
        {imageCategoryDetails.imageCount && (
          <>&nbsp;({imageCategoryDetails.imageCount})</>
        )}
      </span>
      {img}
      {imageCategoryDetails.slug === currentImageCategorySlug && (
        <RightArrow className="h-5 font-bold" />
      )}
    </Link>
  );
};

const ImageCategories = ({
  currentImageCategorySlug,
  onImageCategoryChange,
}) => {
  const {
    // isFetching: imageCategoriesLoading,
    // error: imageCategoriesError,
    data: imageCategoriesRes,
  } = useQuery({
    queryKey: ['imageCategory/all'],
    queryFn: () => ajaxRequest('api/imageCategory/all', ajaxRequestMethods.GET),
    staleTime: ReactQueryParameters.queryStaleTime,
    gcTime: ReactQueryParameters.queryGcTime,
  });
  const imageCategories = imageCategoriesRes?.data;

  const {
    // isFetching: imageCategoriesLoading,
    // error: imageCategoriesError,
    data: imageCountRes,
  } = useQuery({
    queryKey: ['imageCount'],
    queryFn: () => ajaxRequest('api/image/count', ajaxRequestMethods.GET),
  });

  const [showImageCategories, setShowImageCategories] = useState(true);
  const SidebarIcon = showImageCategories ? CrossIcon : BurgerIcon;
  const [packagesCategory, setPackagesCategory] = useState(undefined);

  useEffect(() => {
    if (imageCategories) {
      const packagesCategory = imageCategories.find(
        (category) => category.slug === slugUtils.slugs.packages,
      );
      setPackagesCategory(packagesCategory);
      let currentImageCategoryName = '';
      if (currentImageCategorySlug === slugUtils.slugs.latestDesigns)
        currentImageCategoryName = 'Latest Designs';
      else if (currentImageCategorySlug === slugUtils.slugs.allDesigns)
        currentImageCategoryName = slugUtils.slugs.allDesigns;
      else
        currentImageCategoryName = imageCategories.find(
          (category) => category.slug === currentImageCategorySlug,
        ).imageCategoryName;
      onImageCategoryChange(currentImageCategoryName, false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageCategories]);

  const imageCategoriesRef = useRef(null);
  const onImageCategorySelection = (imageCategoryName) => {
    onImageCategoryChange(imageCategoryName, true);
    if (window.screen.width < 768) setShowImageCategories(false);
  };

  return (
    <div
      ref={imageCategoriesRef}
      className="absolute z-10 flex h-[70vh] md:static"
    >
      <div
        className={`flex flex-col overflow-hidden rounded bg-gray-300 pt-2 transition-all duration-200 ease-in-out ${
          showImageCategories ? 'w-64' : 'w-0'
        }`}
      >
        <label className="p-4 font-impact text-3xl">CATEGORIES</label>
        <div
          className={`h-full overflow-x-hidden ${
            showImageCategories ? 'overflow-y-auto' : 'overflow-y-hidden'
          } w-full`}
        >
          <ImageCategoryLink
            imageCategoryDetails={{
              imageCategoryName: 'Latest Designs',
              slug: slugUtils.slugs.latestDesigns,
            }}
            currentImageCategorySlug={currentImageCategorySlug}
            onImageCategorySelection={onImageCategorySelection}
            textClasses="font-semibold"
            img={
              <img
                src="/new-star.png"
                alt="new arrival indicator"
                className="h-6 w-10 animate-bounce"
              />
            }
          />

          {packagesCategory && (
            <ImageCategoryLink
              imageCategoryDetails={packagesCategory}
              currentImageCategorySlug={currentImageCategorySlug}
              onImageCategorySelection={onImageCategorySelection}
              textClasses="font-semibold"
              img={
                <div
                  className="flex animate-bounce items-center justify-center rounded-xl px-1.5 py-0 text-white/85"
                  style={{ fontSize: '10px', background: 'red' }}
                >
                  Recommended
                </div>
              }
            />
          )}

          <ImageCategoryLink
            imageCategoryDetails={{
              slug: slugUtils.slugs.allDesigns,
              imageCategoryName: 'All Designs',
              imageCount: imageCountRes?.data.imageCount,
            }}
            currentImageCategorySlug={currentImageCategorySlug}
            onImageCategorySelection={onImageCategorySelection}
          />

          {imageCategories?.map(
            ({ _id, slug, imageCategoryName, imageCount }) =>
              slug !== slugUtils.slugs.packages && (
                <ImageCategoryLink
                  key={_id}
                  imageCategoryDetails={{ slug, imageCategoryName, imageCount }}
                  currentImageCategorySlug={currentImageCategorySlug}
                  onImageCategorySelection={onImageCategorySelection}
                />
              ),
          )}
        </div>
      </div>

      <SidebarIcon
        className={`${
          showImageCategories ? '-ml-7' : 'ml-0.5 sm:ml-0'
        } mt-1 cursor-pointer`}
        onClick={() => setShowImageCategories((prev) => !prev)}
      />
    </div>
  );
};

export default ImageCategories;
