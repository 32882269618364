import { Routes, Route, Navigate } from 'react-router-dom';

import CreateNewResource from 'components/AdminComponents/CreateNewResource';
import AddNewImageCategory from 'components/AdminComponents/AddNewImageCategory';
import Marketplace from 'components/Marketplace';
import Login from 'components/Login';
import AdminMasterPage from 'components/AdminComponents/AdminMasterPage';
import routes from 'constants/routes';
import RequireLogin from 'components/Auth/RequireLogin';
import RequireLogout from 'components/Auth/RequireLogout';
import AdminHome from 'components/AdminComponents/AdminHome';
import ImagesTable from 'components/AdminComponents/ImagesTable';
import ExecutePaypalPayment from 'components/Marketplace/ExecutePaypalPayment';
import ImageCategoryList from 'components/AdminComponents/ImageCategoryList';
import Download from 'components/Download';
import routerUtils from 'helpers/routerUtils';
import slugUtils from 'helpers/slugUtils';
import OrderHistory from 'components/AdminComponents/OrderHistory';
import Discount from 'components/AdminComponents/Discount';
import VerifyEmail from 'components/VerifyEmail';
import Unsubscribe from 'components/Unsubscribe';
import AccountSettingsTabButtons from 'components/AdminComponents/AccountSettings/AccountSettingsTabButtons';
import ChangePassword from 'components/AdminComponents/AccountSettings/ChangePassword';

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Navigate
            to={routerUtils.getMarketplaceNavigateUrl(
              slugUtils.slugs.latestDesigns,
              1,
            )}
            replace
          />
        }
      />
      <Route
        path={routes.addNewImage}
        element={
          <RequireLogin>
            <AdminMasterPage>
              <CreateNewResource />
            </AdminMasterPage>
          </RequireLogin>
        }
      />
      <Route
        path={routes.addNewImageCategory}
        element={
          <RequireLogin>
            <AdminMasterPage>
              <AddNewImageCategory />
            </AdminMasterPage>
          </RequireLogin>
        }
      />
      <Route
        path={routes.images}
        element={
          <RequireLogin>
            <AdminMasterPage>
              <ImagesTable />
            </AdminMasterPage>
          </RequireLogin>
        }
      />

      <Route path={routes.verifyEmail} element={<VerifyEmail />} />

      <Route path={routes.unsubcribe} element={<Unsubscribe />} />

      <Route
        path={routes.login}
        element={
          <RequireLogout>
            <Login />
          </RequireLogout>
        }
      />

      <Route
        path={routes.adminAppHome}
        element={
          <RequireLogin>
            <AdminMasterPage>
              <AdminHome />
            </AdminMasterPage>
          </RequireLogin>
        }
      />

      <Route
        path={routes.imageCategories}
        element={
          <RequireLogin>
            <AdminMasterPage>
              <ImageCategoryList />
            </AdminMasterPage>
          </RequireLogin>
        }
      />

      <Route path={routes.accountSettings.baseRoute}>
        <Route
          path={routes.accountSettings.routes.password}
          element={
            <RequireLogin>
              <AdminMasterPage>
                <div className="flex flex-col gap-y-3">
                  <AccountSettingsTabButtons />
                  <ChangePassword />
                </div>
              </AdminMasterPage>
            </RequireLogin>
          }
        />

        <Route
          path={routes.accountSettings.routes.email}
          element={
            <RequireLogin>
              <AdminMasterPage>
                <AccountSettingsTabButtons />
                {/* <ChangeEmail /> */}
              </AdminMasterPage>
            </RequireLogin>
          }
        />

        <Route
          path=""
          element={
            <Navigate to={routes.accountSettings.routes.password} replace />
          }
        />
      </Route>

      <Route
        path={routes.orderHistory}
        element={
          <RequireLogin>
            <AdminMasterPage>
              <OrderHistory />
            </AdminMasterPage>
          </RequireLogin>
        }
      />

      <Route
        path={routes.discount}
        element={
          <RequireLogin>
            <AdminMasterPage>
              <Discount />
            </AdminMasterPage>
          </RequireLogin>
        }
      />

      <Route
        path={routes.executePaypalPayment}
        element={<ExecutePaypalPayment />}
      />

      <Route path={routes.download} element={<Download />} />

      <Route path={routes.marketplace} element={<Marketplace />} />
    </Routes>
  );
}

export default App;
